<template>
  <div class="h-100">
    <div class="flex-cloumn h-100">
      <div class="tab">
        <!-- tab栏 -->
        <div
          class="tab-item"
          v-for="item in tabMenu"
          :key="item.id"
          :class="curTab === item.id ? 'tab-item-active' : ''"
          @click="curTab = item.id"
        >
          {{ item.name }}
        </div>
      </div>
      <!-- 商城商品筛选表单 -->
      <div class="table-filter" v-if="curTab === 1">
        <div class="btn-wrap">
          <div @click="openDialog('addShop')" class="creatNewBtn">新建商品</div>
          <div style="display: flex">
            <div class="title">商城展区</div>
            <div @click="openDialog('shopShow')" class="btn">管理</div>
            <div @click="openDialog('shopShow')" class="btn">新建</div>
          </div>
        </div>
        <div class="select-wrap">
          <div>
            <el-select
              v-model="tableFilter1.productTypes"
              style="width: 100px"
              class="select-item"
              size="mini"
              placeholder="商品类型"
            >
              <el-option
                v-for="(option, index) in selectArr.goodsType"
                :key="index"
                :label="option.label"
                :value="option.value"
              ></el-option>
            </el-select>

            <el-select
              v-model="tableFilter1.exhibitionIds"
              class="select-item"
              style="width: 100px"
              size="mini"
              placeholder="商品展区"
            >
              <el-option label="全部" :value="zoneId"></el-option>
              <el-option
                v-for="(option, index) in zone"
                :key="index"
                :label="option.exhibitionName"
                :value="option.id"
              ></el-option>
            </el-select>
            <!-- <el-select
              v-model="tableFilter1.discount"
              class="select-item"
              style="width: 100px"
              size="mini"
              placeholder="折扣"
            >
              <el-option
                v-for="(option, index) in selectArr.discount"
                :key="index"
                :label="option.label"
                :value="option.value"
              ></el-option>
            </el-select> -->
            <el-select
              v-model="tableFilter1.buyAttributes"
              class="select-item"
              style="width: 100px"
              size="mini"
              placeholder="付款方式"
            >
              <el-option
                v-for="(option, index) in selectArr.pay"
                :key="index"
                :label="option.label"
                :value="option.value"
              ></el-option>
            </el-select>
            <el-select
              v-model="tableFilter1.saleStatus"
              class="select-item"
              style="width: 100px"
              size="mini"
              placeholder="发售状态"
            >
              <el-option
                v-for="(option, index) in selectArr.status"
                :key="index"
                :label="option.label"
                :value="option.value"
              ></el-option>
            </el-select>
            <span>商城显隐：</span>
            <el-switch
              @change="changeShopDisplay"
              v-model="shopDisplay"
              active-color="#13ce66"
              inactive-color="#ff4949"
            >
            </el-switch>
          </div>
          <el-input
            v-model="tableFilter1.productName"
            style="width: 226px"
            placeholder="搜索关键字"
            size="mini"
          ></el-input>
        </div>
        <div class="flex-row space-between">
          <div>
            <span>发售日期：</span>
            <el-date-picker
              v-model="tableFilter1.date"
              type="daterange"
              size="mini"
              range-separator="-"
              style="width: 280px"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker>
            <span style="margin-left: 10px">价格区间：</span>
            <el-input
              :disabled="tableFilter1.buyAttributes === null"
              v-model="tableFilter1.startPrice"
              style="width: 130px"
              size="mini"
              placeholder="请选择付款方式"
            ></el-input>
            -
            <el-input
              :disabled="tableFilter1.buyAttributes === null"
              v-model="tableFilter1.endPrice"
              style="width: 130px"
              size="mini"
              placeholder="请选择付款方式"
            ></el-input>
          </div>
          <div>
            <el-select
              v-model="tableFilter1.sort"
              class="select-item"
              style="width: 100px"
              size="mini"
              placeholder="排序"
            >
              <el-option
                v-for="(option, index) in selectArr.sort"
                :key="index"
                :label="option.label"
                :value="option.value"
              ></el-option>
            </el-select>
            <el-select
              disabled
              class="select-item"
              style="width: 100px"
              size="mini"
              placeholder="批量管理"
              v-model="l"
            >
              <el-option
                v-for="(option, index) in list"
                :key="index"
                :label="option.name"
                :value="option.id"
              ></el-option>
            </el-select>
          </div>
        </div>
      </div>
      <!-- 剧本商品筛选表单 -->
      <div class="table-filter" v-else-if="curTab === 2">
        <div class="btn-wrap">
          <div class="creatNewBtn" @click="openDialog('addScript')">
            发售剧本
          </div>
        </div>
        <div class="select-wrap">
          <div>
            <el-select
              v-model="tableFilter2.productTypes"
              style="width: 100px"
              class="select-item"
              size="mini"
              placeholder="题材"
            >
              <el-option
                v-for="(option, index) in selectArr.scriptType"
                :key="index"
                :label="option.label"
                :value="option.value"
              ></el-option>
            </el-select>

            <el-input
              v-model="tableFilter2.playNum"
              class="select-item"
              style="width: 100px"
              size="mini"
              placeholder="人数"
            >
            </el-input>
            <el-select
              v-model="tableFilter2.buyAttributes"
              class="select-item"
              style="width: 100px"
              size="mini"
              placeholder="付款方式"
            >
              <el-option
                v-for="(option, index) in selectArr.pay"
                :key="index"
                :label="option.label"
                :value="option.value"
              ></el-option>
            </el-select>
            <el-select
              v-model="tableFilter2.saleStatus"
              class="select-item"
              style="width: 100px"
              size="mini"
              placeholder="发售状态"
            >
              <el-option
                v-for="(option, index) in selectArr.status"
                :key="index"
                :label="option.label"
                :value="option.value"
              ></el-option>
            </el-select>
          </div>
          <el-input
            v-model="tableFilter2.productName"
            style="width: 226px"
            placeholder="搜索关键字"
            size="mini"
          ></el-input>
        </div>
        <div class="flex-row space-between">
          <div>
            <span>发售日期：</span>
            <el-date-picker
              v-model="tableFilter2.date"
              type="daterange"
              size="mini"
              range-separator="-"
              style="width: 280px"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker>
            <span style="margin-left: 10px">价格区间：</span>
            <el-input
              :disabled="tableFilter2.buyAttributes === null"
              v-model="tableFilter2.startPrice"
              style="width: 130px"
              size="mini"
              placeholder="请选择付款方式"
            ></el-input>
            -
            <el-input
              :disabled="tableFilter2.buyAttributes === null"
              v-model="tableFilter2.endPrice"
              style="width: 130px"
              size="mini"
              placeholder="请选择付款方式"
            ></el-input>
          </div>
          <div>
            <el-select
              v-model="tableFilter2.sort"
              class="select-item"
              style="width: 100px"
              size="mini"
              placeholder="排序"
            >
              <el-option
                v-for="(option, index) in selectArr.sort"
                :key="index"
                :label="option.label"
                :value="option.value"
              ></el-option>
            </el-select>
            <el-select
              class="select-item"
              style="width: 100px"
              size="mini"
              placeholder="批量管理"
              v-model="l"
            >
              <el-option
                v-for="(option, index) in list"
                :key="index"
                :label="option.name"
                :value="option.id"
              ></el-option>
            </el-select>
          </div>
        </div>
      </div>
      <!-- 剧本内购筛选表单 -->
      <div class="table-filter" v-else-if="curTab === 3">
        <div class="btn-wrap">
          <div class="creatNewBtn" @click="openDialog('addInterShop')">
            新建商品
          </div>
        </div>
        <div class="select-wrap">
          <div>
            <el-select
              v-model="tableFilter3.productType"
              style="width: 100px"
              class="select-item"
              clearable
              size="mini"
              placeholder="商品类型"
            >
              <el-option
                v-for="(option, index) in selectArr.interShopType"
                :key="index"
                :label="option.label"
                :value="option.value"
              ></el-option>
            </el-select>

            <el-select
              v-model="tableFilter3.currencyType"
              class="select-item"
              style="width: 100px"
              size="mini"
              placeholder="付款方式"
            >
              <el-option
                v-for="(option, index) in selectArr.currencyTypeOptions"
                :key="index"
                :label="option.label"
                :value="option.value"
              ></el-option>
            </el-select>
            <el-select
              v-model="tableFilter3.saleStatus"
              class="select-item"
              style="width: 100px"
              size="mini"
              placeholder="发售状态"
            >
              <el-option
                v-for="(option, index) in selectArr.status"
                :key="index"
                :label="option.label"
                :value="option.value"
              ></el-option>
            </el-select>
          </div>
          <el-input
            v-model="tableFilter3.productName"
            style="width: 226px"
            placeholder="搜索关键字"
            size="mini"
          ></el-input>
        </div>
        <!-- <div class="flex-row space-between">
          <div>
            <span>发售日期：</span>
            <el-date-picker
              v-model="tableFilter3.date"
              type="daterange"
              size="mini"
              range-separator="-"
              style="width: 280px"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker>
            <span style="margin-left: 10px">价格区间：</span>
            <el-input
              :disabled="tableFilter3.buyAttributes === null"
              v-model="tableFilter3.startPrice"
              style="width: 130px"
              size="mini"
              placeholder="请选择付款方式"
            ></el-input>
            -
            <el-input
              :disabled="tableFilter3.buyAttributes === null"
              v-model="tableFilter3.endPrice"
              style="width: 130px"
              size="mini"
              placeholder="请选择付款方式"
            ></el-input>
          </div>
          <div>
            <el-select
              v-model="tableFilter3.sort"
              class="select-item"
              style="width: 100px"
              size="mini"
              placeholder="排序"
            >
              <el-option
                v-for="(option, index) in selectArr.sort"
                :key="index"
                :label="option.label"
                :value="option.value"
              ></el-option>
            </el-select>
            <el-select
              class="select-item"
              style="width: 100px"
              size="mini"
              placeholder="批量管理"
              v-model="l"
            >
              <el-option
                v-for="(option, index) in list"
                :key="index"
                :label="option.name"
                :value="option.id"
              ></el-option>
            </el-select>
          </div>
        </div> -->
      </div>
      <div  v-loading="loading" class="row2 flex-1  shadow" style="overflow: auto">
        <el-card class="row2-card">
          <!-- 表头 -->
          <div class="flex-row table-header">
            <div style="width: 156px; text-align: left">名称</div>
            <div style="width: 210.5px">简要</div>
            <div style="width: 150px">价格</div>
            <div style="width: 140px">发售日期</div>
            <div style="width: 82px">操作</div>
            <div style="width: 110px">状态</div>
          </div>
          <!-- 行数据 -->
          <div
            @click="
              openDialog(
                curTab === 1
                  ? 'addShop'
                  : curTab === 2
                  ? 'addScript'
                  : 'addInterShop',
                curTab !== 3 ? item.id : item
              )
            "
            class="item"
            v-for="(item, i) in list"
            :key="i"
            :style="{
              background: i % 2 !== 0 ? '#fff' : 'rgba(250,249,255,1)',
            }"
          >
            <el-checkbox></el-checkbox>
            <!-- 商品序号 -->
            <div class="item-index">
              {{ i + 1 }}
            </div>
            <div class="flex-row space-around flex-1">
              <!-- 商品名称 -->
              <div class="item-title shenglue-1" style="width: 20%">
                {{ item.productName }}
              </div>
              <!-- 商品类型折扣 -->
              <div
                v-if="curTab !== 3"
                class="item-message shenglue-1"
                style="width: 27%"
              >
                {{ typeArr[item.productType] }} /
                {{ curTab === 1 ? item.productLabel : item.playNum + "人" }} /
                {{ (item.discount * 10).toFixed(2) }}折
              </div>
              <!-- 商品类型折扣 -->
              <div
                v-if="curTab === 3"
                class="item-message shenglue-1"
                style="width: 27%; text-align: center"
              >
                {{ item.desc }}
              </div>
              <!-- 商品现价 -->
              <div class="item-other shenglue-1" style="width: 15%">
                {{ item.nowPrice
                }}{{ item.buyAttributeStr || priceType[item.priceType - 1] }}
              </div>
              <div class="item-other">
                <!-- 剧本上传日期 -->
                <div class="item-date shenglue-1">
                  {{
                    item[curTab !== 3 ? "saleTime" : "sellTime"]
                      ? item[curTab !== 3 ? "saleTime" : "sellTime"]
                      : "暂无日期"
                  }}
                </div>
                <!-- 发售状态按钮 -->
                <!-- <div
                  @click.stop="saleOperation(item.id)"
                  class="item-check-true"
                  :style="{
                    background:
                      item.saleStatus === 1 ? '#fff' : 'rgb(127, 118, 189)',
                    color: item.saleStatus === 1 ? '#000' : '#fff',
                  }"
                >
                  {{ item.saleStatus === 1 ? "停售" : "立即发售" }}
                </div> -->
                <div
                  v-if="
                    curTab === 1 &&
                    item.saleStatus === 2 &&
                    item.productType === 4
                  "
                  @click.stop="openDialog('addShop', item.id, true)"
                  class="item-check-true"
                >
                  新建重发
                </div>
                <div
                  v-else
                  @click.stop="
                    saleOperation(
                      item[curTab !== 3 ? 'id' : 'productId'],
                      item[curTab !== 3 ? 'saleStatus' : 'sellStatus']
                    )
                  "
                  class="item-check-true shenglue-1"
                  :style="{
                    background:
                      !loading &&
                      checkSaleStatusBg(item.saleStatus || item.sellStatus),
                    color: checkSaleStatusColor(
                      item.saleStatus || item.sellStatus
                    ),
                  }"
                >
                  {{ checkSaleStatusText(item.saleStatus || item.sellStatus) }}
                </div>
                <!-- 发售状态 -->
                <div class="item-state">
                  <div
                    class="state"
                    :style="{
                      background:
                        statusArr[item.saleStatus || item.sellStatus].bg,
                    }"
                  >
                    {{ statusArr[item.saleStatus || item.sellStatus].label }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </el-card>
      </div>
    </div>
    <div class="row3" v-if="curTab === 1">
      <el-pagination
        :total="total"
        :current-page.sync="tableFilter1.page"
        :page-size.sync="tableFilter1.limit"
        layout="prev, pager, next"
      ></el-pagination>
    </div>
    <div class="row3" v-else-if="curTab === 2">
      <el-pagination
        :total="total"
        :current-page.sync="tableFilter2.page"
        :page-size.sync="tableFilter2.limit"
        layout="prev, pager, next"
      ></el-pagination>
    </div>
    <div class="row3" v-else>
      <el-pagination
        :total="total"
        @current-change="getManageProductList"
        :current-page.sync="tableFilter3.currPage"
        :page-size.sync="tableFilter3.limit"
        layout="prev, pager, next"
      ></el-pagination>
    </div>
    <add-shop
      ref="addShop"
      @openAddShow="openDialog('shopShow')"
      @getShopList="getShopList"
      :id="editId"
      :isNew="isNew"
    />
    <add-script
      :id="editId"
      ref="addScript"
      :zone="zone"
      @openAddShow="openDialog('shopShow')"
      @getShopList="getShopList"
    />
    <add-interShop
      :interShop="interShop"
      :id="editId"
      ref="addInterShop"
      :zone="zone"
      @openAddShow="openDialog('shopShow')"
      @getShopList="getShopList"
    />
    <shop-show ref="shopShow" @getZoneList="getZoneList" />
  </div>
</template>
<script>
// import jsonData from "../../../jsonData/shopList.json";
import addShop from "./common/component/addShop";
import addScript from "./common/component/addScript";
import addInterShop from "./common/component/addInterShop";
import shopShow from "./common/component/shopShow";
export default {
  props: {},
  components: {
    addShop,
    addScript,
    addInterShop,
    shopShow,
  },
  created() {
    // 作品管理发售剧本跳过了
    if (this.$route.params.isScenario) {
      this.curTab = 2;
      this.tableFilter2.productName = this.$route.params.scenarioName;
    }

    this.getShopList();
    this.getZoneList();
    this.getShopDisplay();
  },
  data() {
    // 筛选选择框集合
    this.selectArr = {
      goodsType: [
        { label: "全部", value: null },
        { label: "点券商品", value: 3 },
        { label: "其他商品", value: 4 },
        { label: "商品礼包", value: 5 },
      ],
      scriptType: [
        { label: "全部", value: null },
        { label: "单人本", value: 1 },
        { label: "完整本", value: 2 },
      ],
      pay: [
        { label: "全部", value: null },
        { label: "人民币", value: [1] },
        { label: "点券", value: [2] },
        { label: "金币", value: [3] },
      ],
      status: [
        { label: "全部状态", value: null },
        { label: "已发售", value: 1 },
        { label: "停售", value: 2 },
        { label: "未发售", value: 0 },
      ],
      sort: [
        { label: "初始排序", value: null },
        { label: "时间升序", value: "1" },
        { label: "时间降序", value: "2" },
        { label: "折扣升序", value: "3" },
        { label: "折扣降序", value: "4" },
      ],
      interShopType: [
        { label: "全部类型", value: null },
        { label: "剧本内容", value: 1 },
        { label: "线索", value: 2 },
      ],
      currencyTypeOptions: [
        { label: "全部金额", value: null },
        { label: "人民币", value: 1 },
        { label: "点券", value: 2 },
        { label: "金币", value: 3 },
      ],
    };
    // 商品类型展示
    this.typeArr = ["", "单人本", "完整本", "点券商品", "其他商品", "商品礼包"];
    // 商品状态展示
    this.statusArr = [
      { label: "未发售", bg: "rgb(215, 213, 223)" },
      { label: "已发售", bg: "rgb(139, 209, 203)" },
      { label: "停售", bg: "rgb(215, 213, 223)" },
    ];
    this.priceType = ["人民币", "点券", "金币"];
    return {
      zone: [],
      l: "",
      editId: null,
      loading: false,
      isNew: false,
      // tab栏
      curTab: 1,
      tabMenu: [
        { name: "商城商品", id: 1 },
        { name: "剧本商品", id: 2 },
        { name: "剧本内购", id: 3 },
      ],
      tableFilter1: {
        date: [],
        productTypes: null,
        exhibitionIds: null,
        buyAttributes: null,
        sort: null,
        saleStatus: null,
        startDate: null,
        endDate: null,
        startPrice: null,
        endPrice: null,
        scenarioType: null,
        playNum: null,
        page: 1,
        limit: 50,
      },
      tableFilter2: {
        date: [],
        productTypes: null,
        exhibitionIds: null,
        buyAttributes: null,
        sort: null,
        saleStatus: null,
        startDate: null,
        endDate: null,
        startPrice: null,
        endPrice: null,
        scenarioType: null,
        playNum: null,
        productName: "",
        page: 1,
        limit: 50,
      },
      tableFilter3: {
        currPage: 1,
        limit: 50,
        productCategory: 3,
        type: 2,
        productType: null,
        currencyType: null,
        saleStatus: null,
        productName: "",
      },
      list: [],
      total: 100,
      shopDisplay: true,
      productModel: {
        currPage: 1,
        limit: 50,
        productCategory: 3,
        type: 2,
        productType: null,
        currencyType: null,
        saleStatus: null,
      },
      interShop: null,
    };
  },
  methods: {
    async getManageProductList() {
      this.loading = true;
      const res = await this.$api.shop
        .getManageProductList(this.tableFilter3)
        .finally(() => {
          this.loading = false;
        });
      const list = res.data;
      list.forEach((item) => {
        item.desc = `${item.productType === 1 ? "剧本内容" : "线索"}${
          item.summary ? "/" + item.summary : ""
        }/${item.discount}折`;
      });
      this.list = list;
      this.total = res.count;
    },
    async getShopList() {
      this.loading = true;
      const data = await this.$api.shop
        .getShopList(this.dealParams(this[`tableFilter${this.curTab}`]))
        .finally(() => {
          this.loading = false;
        });
      window.scr = data;
      this.list =
        this.curTab === 1
          ? data.data
          : data.data.sort((a, b) => (a.productName > b.productName ? 1 : -1));
      this.total = data.count;
    },
    async getShopDisplay() {
      const data = await this.$api.shop.getShopDisplay();
      this.shopDisplay = data.data[0].configValue === "1" ? true : false;
    },
    async changeShopDisplay() {
      const data = await this.$api.shop.changeShopDisplay(
        this.shopDisplay ? 1 : 2
      );
      this.$message.success(data.respMsg);
    },
    async getZoneList() {
      const data = await this.$api.shop.getZoneList();
      this.zone = data;
    },
    dealParams(obj) {
      const params = JSON.parse(JSON.stringify(obj));
      params.productCategory = this.curTab === 1 ? 2 : 1;
      const arr = this.curTab === 1 ? [3, 4, 5] : [1, 2];
      params.productTypes =
        params.productTypes === null ? arr : [params.productTypes];
      params.exhibitionIds =
        params.exhibitionIds === null ? null : [params.exhibitionIds].flat();
      params.buyAttributes =
        params.buyAttributes === null ? null : params.buyAttributes;
      params.saleStatus =
        params.saleStatus === null ? null : [params.saleStatus];
      params.scenarioType =
        params.scenarioType === null ? null : [params.scenarioType];
      if (params.date && params.date.length) {
        params.startDate = params.date[0];
        params.endDate = params.date[1];
      }
      delete params.date;
      return params;
    },
    saleOperation(id, status) {
      if (this.curTab === 1 && status === 2) return;
      this.$confirm(
        `您确定要${status === 1 ? "停" : "发"}售此${
          this.curTab === 1 ? "商品" : "剧本"
        }吗？`,
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).then(async () => {
        await this.$api.shop.goodsStatusOperation({ sellNowProductIds: [id] });
        this.curTab === 3 ? this.getManageProductList() : this.getShopList();
      });
    },
    openDialog(ref, id = null, isNew = false) {
      this.isNew = isNew;
      if (typeof id === "object") {
        this.interShop = id;
      }
      this.editId = typeof id !== "object" ? id : "";
      this.$refs[ref].showDialog = true;
    },
    checkSaleStatusBg(status) {
      const tab = this.curTab;
      return (tab === 1 && status) ||
        (tab === 2 && status === 1) ||
        (tab === 3 && status === 1)
        ? "#fff"
        : "rgb(127, 118, 189)";
    },
    checkSaleStatusColor(status) {
      return (
        this.curTab === 1 ? ["#fff", "#000", "#999"] : ["#fff", "#000", "#fff"]
      )[status];
    },
    checkSaleStatusText(status) {
      return (
        this.curTab === 1
          ? ["发售", "停售", "已停售"]
          : ["发售", "停售", "发售"]
      )[status];
    },
  },
  watch: {
    tableFilter1: {
      handler() {
        this.getShopList();
      },
      deep: true,
    },
    tableFilter2: {
      handler() {
        this.getShopList();
      },
      deep: true,
    },
    tableFilter3: {
      handler() {
        this.getManageProductList();
      },
      deep: true,
    },
    curTab(v) {
      v === 3 ? this.getManageProductList() : this.getShopList();
    },
  },
  computed: {
    zoneId() {
      return this.zone.map((item) => item.id);
    },
  },
};
</script>
<style lang="scss" scope>
@import "./common/style/shop.scss";
@import "./common/style/form.scss";
</style>