<template>
  <el-dialog top="3vh" :visible.sync="showDialog" width="760px">
    <div class="dialog">
      <!-- 名称 -->
      <div class="flex-row flex-ac">
        <div class="title">
          <span style="color: red; font-size: 16px"></span>名称
        </div>
        <el-select
          size="mini"
          placeholder="请选择已过审剧本"
          @change="changeName"
          filterable
          v-model="form.scenarioId"
        >
          <el-option
            v-for="(option, index) in scripts"
            :key="index"
            :label="option.scenarioName"
            :value="option.id"
          ></el-option>
        </el-select>
      </div>
      <!-- 商品类型 -->
      <div class="flex-row flex-ac">
        <div class="title">
          <span style="color: red; font-size: 16px"></span>商品类型
        </div>
        <el-button-group style="overflow: hidden; border-radius: 10px">
          <el-button
            round
            @click="changeType(1)"
            :type="form.productType === 1 ? 'primary' : ''"
            size="mini"
            >剧本内容</el-button
          >
          <el-button
            round
            @click="changeType(2)"
            :type="form.productType === 2 ? 'primary' : ''"
            size="mini"
            >线索</el-button
          >
        </el-button-group>
      </div>
      <!-- 付费内容 -->
      <div class="flex mb-2">
        <div class="title" style="height: 27px">付费内容</div>
        <!-- <span
          class="pay-tip"
          >请选择过审剧本</span -->
        <el-tree
          v-if="form.productType === 1"
          style="width: 100%"
          :data="treeData"
          :props="{
            label: 'name',
            children: 'childTree',
          }"
          show-checkbox
          ref="tree"
          node-key="id"
          :check-strictly="false"
          :default-checked-keys="checkedKeys"
          :default-expanded-keys="checkedKeys"
          default-expand-all
        >
          <span class="custom-tree-node" slot-scope="{ data }">
            <div
              style="
                width: 500px;
                height: 30px;
                overflow: hidden;
                line-height: 30px;
              "
              v-html="data.name"
            ></div>
          </span>
        </el-tree>

        <div class="flex" v-else>
          <div v-if="!form.scenarioId">请选择已过审剧本</div>
          <el-select
            class="mr-3"
            v-if="form.scenarioId"
            size="mini"
            @change="
              () => {
                clueIndex = '';
              }
            "
            placeholder="请选择幕数"
            filterable
            v-model="chapterIndex"
          >
            <el-option
              v-for="(option, chapterIndex) in clueData"
              :key="chapterIndex"
              :label="option.name"
              :value="chapterIndex"
            ></el-option>
          </el-select>

          <el-select
            v-if="form.scenarioId"
            size="mini"
            placeholder="请选择线索"
            filterable
            v-model="clueIndex"
          >
            <el-option
              v-for="(option, clueIndex) in clueData[chapterIndex]
                ? clueData[chapterIndex].childTree
                : []"
              :key="clueIndex"
              :disabled="!!option.disable"
              :label="option.name"
              :value="clueIndex"
            ></el-option>
          </el-select>
        </div>
      </div>
      <div class="flex mb-2" v-if="form.productType === 1">
        <div class="title">
          <span style="color: red; font-size: 16px"></span>付费时机
        </div>
        <div v-if="!form.scenarioId">请选择已过审剧本</div>
        <el-select
          class="mr-3"
          v-if="form.scenarioId"
          size="mini"
          @change="
            () => {
              payTipIndex = '';
            }
          "
          placeholder="请选择幕数"
          filterable
          v-model="payTipChapterIndex"
        >
          <el-option
            v-for="(option, chapterIndex) in payTipData"
            :key="chapterIndex"
            :label="option.name"
            :value="chapterIndex"
          ></el-option>
        </el-select>

        <el-select
          v-if="form.scenarioId"
          size="mini"
          placeholder="请选择付费阶段"
          filterable
          v-model="payTipIndex"
        >
          <el-option
            v-for="option in payTipData[payTipChapterIndex]
              ? payTipData[payTipChapterIndex].childTree
              : []"
            :key="option.id"
            :disabled="!!option.disable"
            :label="option.name"
            :value="option.id"
          ></el-option>
        </el-select>
      </div>
      <!-- 价格 -->
      <div class="flex-row flex-ac">
        <div class="title">
          <span style="color: red; font-size: 16px"></span>价格
        </div>
        <span>原价：</span>
        <el-input
          onkeyup="this.value= this.value.match(/\d+(\.\d{0,2})?/) ? this.value.match(/\d+(\.\d{0,2})?/)[0] : ''"
          v-model="form.originalPrice"
          style="width: 65px; margin-right: 20px"
          size="mini"
        ></el-input>
        <span>现价：</span>
        <el-input
          onkeyup="this.value= this.value.match(/\d+(\.\d{0,2})?/) ? this.value.match(/\d+(\.\d{0,2})?/)[0] : ''"
          v-model="form.nowPrice"
          style="width: 65px; margin-right: 20px"
          size="mini"
        ></el-input>
        <el-radio-group v-model="form.priceType" size="mini">
          <el-radio-button :label="2">点券</el-radio-button>
          <el-radio-button :label="3">金币</el-radio-button>
        </el-radio-group>
      </div>
      <!-- 折扣 -->
      <div class="flex-row flex-ac">
        <div class="title">折扣</div>
        <span style="font-weight: 600; margin-right: 10px">{{ discount }}</span
        >折
      </div>
      <!-- 购买限制 -->
      <div class="flex-row flex-as">
        <div class="title">
          <span style="color: red; font-size: 16px"></span>购买限制
        </div>
        <div>
          <div class="flex-row flex-ac">
            <div class="my-radio">
              <div class="my-radio-choice"></div>
            </div>
            <el-select size="mini" v-model="form.buyRestriction">
              <el-option
                v-for="(option, index) in selectArr.limitPeople"
                :key="index"
                :label="option.label"
                :value="option.value"
              ></el-option>
            </el-select>
            <span style="margin-left: 10px">可购买此商品；</span>
          </div>
        </div>
      </div>
      <!-- 商品说明 -->
      <div class="flex-row flex-as p-r">
        <div class="title">
          <span style="color: red; font-size: 16px"></span>商品说明
        </div>
        <el-input
          v-model="form.productDesc"
          style="width: 430px"
          size="mini"
          placeholder="请输入说明"
        ></el-input>
      </div>
    </div>
    <span slot="footer" class="dialog-footer" v-if="form.sellStatus !== 1">
      <el-button
        v-if="interShop && interShop.productId"
        size="mini"
        plain
        type="danger"
        @click="deleteScript"
        >删除</el-button
      >
      <el-button
        :loading="loadingArr[0]"
        size="mini"
        type="danger"
        @click="checkZeroPrice(0)"
        v-if="form.sellStatus !== 2"
        >保存未发售</el-button
      >
      <el-button
        :loading="loadingArr[2]"
        size="mini"
        type="info"
        plain
        @click="checkZeroPrice(2)"
        >停售</el-button
      >
      <el-button
        :loading="loadingArr[1]"
        size="mini"
        type="primary"
        @click="checkZeroPrice(1)"
        >立即发售</el-button
      >
    </span>
    <span
      slot="footer"
      class="dialog-footer"
      style="color: #999; font-size: 18px"
      v-else
    >
      该剧本现在为发售状态，无法编辑
    </span>
  </el-dialog>
</template>

<script>
import { formRule } from "@tools";
export default {
  props: {
    id: {
      default: null,
    },
    interShop: {
      default: () => {},
    },
  },
  data() {
    this.selectArr = {
      limitPeople: [
        { label: "所有人", value: 1 },
        { label: "首充用户", value: 2 },
      ],
    };
    this.formRules = [
      {
        label: "scenarioId",
        name: "剧本",
        rule: {
          required: true,
        },
      },
      {
        label: "nowPrice",
        name: "现价",
        rule: {
          zero: true,
        },
      },
      {
        label: "originalPrice",
        name: "原价",
        rule: {
          zero: true,
        },
      },
      {
        label: "payChapterTip",
        name: "付费幕数",
        rule: {
          required: true,
        },
      },
      {
        label: "payStageTip",
        name: "付费阶段",
        rule: {
          required: true,
        },
      },
    ];
    return {
      scripts: [],
      showDialog: false,
      form: {
        buyRestriction: 1, //	购买限制:(购买限制 1：所有人 2：首充用户)
        characterDetailTree: [],
        discount: 0, //折扣
        nowPrice: 0, //现价
        originalPrice: 0, //原价
        priceType: 2, //价格类型:( 1：人民币 2：点券 3：金币)
        productCategory: 3, //商品类目:( 3:剧本内购)
        productId: "", //商品ID
        productName: "", //商品名称
        productType: 1, //商品类型: 剧本内购(1:剧本内容 2:线索)
        scenarioId: "", //剧本ID
        sellStatus: 0, //发售状态:(0：保存未发售 1：发售 2：停售 )
        productDesc: "一次购买即解锁全部剧本内容！", //商品说明
        payChapterTip: "",
        payStageTip: "",
      },
      checkedKeys: [],
      treeData: [],
      loadingArr: [false, false, false, false],
      clueData: [],
      clueIndex: "",
      chapterIndex: 0,
      payTipData: [],
      payTipIndex: "",
      payTipChapterIndex: 0,
    };
  },
  created() {
    this.getScriptList();
  },
  methods: {
    async getPayTip(id) {
      // return
      const res = await this.$api.shop.getPayTip(id);
      res.forEach((items) => {
        if (items.childTree) {
          items.childTree = items.childTree.filter((item) => +item.id < 5);
        }
      });
      this.payTipData = res;
      this.clueData = res;
    },
    async getScenarioPaidClue(id) {
      const res = await this.$api.shop.getScenarioPaidClue(id);
      this.clueData = res;
    },
    async getScenarioPaidContent(id) {
      const res = await this.$api.shop.getScenarioPaidContent(id);
      this.treeData = res;
    },
    _dataTree(arr, parentId = "parentId") {
      // 建立树映射，构建树结构
      const treeMap = arr.reduce((map, cur) => {
        map[cur.id] = cur;
        return map;
      }, {});
      const list = arr.reduce((arrList, cur) => {
        const pId = cur[parentId];
        const parent = treeMap[pId];
        if (parent) {
          parent.childTree.length
            ? parent.childTree.push(cur)
            : (parent.childTree = [cur]);
        } else if (pId == "0") {
          arrList.push(cur);
        }
        return arrList;
      }, []);
      return list;
    },
    changeType(v) {
      this.form.productType = v;
      const id = this.form.scenarioId;
      if (id) {
        this.form.productType === 1
          ? this.getScenarioPaidContent(id)
          : this.getScenarioPaidClue(id);
        this.form.productDesc =
          this.form.productType === 1
            ? "一次购买即解锁全部剧本内容！"
            : "购买后您将拥有此线索，与您同局玩家可见。";
      }
    },
    changeName(id) {
      const item = this.scripts.find((item) => item.id === id);
      this.form.productName = item.scenarioName;
      if (this.form.productType === 1) {
        this.getPayTip(id);
        this.getScenarioPaidContent(id);
      } else {
        this.clueIndex = "";
        this.getScenarioPaidClue(id);
      }

      // this.form.productType === 1
      //   ? this.getScenarioPaidContent(id)
      //   : this.getScenarioPaidClue(id);
    },
    async getScriptList() {
      const data = await this.$api.shop.getScriptList({
        page: 1,
        limit: 100,
      });
      this.scripts = data.data;
    },
    async checkZeroPrice(sellStatus) {
      const form = JSON.parse(JSON.stringify(this.form));
      if (form.productType === 1) {
        form.payChapterTip = this.payTipChapterIndex;
        form.payStageTip = this.payTipIndex;
        if (!formRule(form, this.formRules)) return;
        const treeList = [
          ...this.$refs.tree.getHalfCheckedNodes(),
          ...this.$refs.tree.getCheckedNodes(),
        ].map(({ id, parentId }) => {
          return {
            id,
            parentId,
            childTree: [],
          };
        });
        form.characterDetailTree = this._dataTree(treeList);
      } else {
        const {
          id,
          name,
          parentId,
          childTree: child,
        } = this.clueData[this.chapterIndex];
        const childTree = child.length ? [child[this.clueIndex]] : [];
        form.characterDetailTree = [{ id, name, parentId, childTree }];
      }
      form.sellStatus = sellStatus;
      form.nowPrice = +form.nowPrice;
      form.originalPrice = +form.originalPrice;
      form.payChapterTip =
        form.productType === 1 ? +this.payTipChapterIndex : null;
      form.payStageTip = form.productType === 1 ? +this.payTipIndex : null;
      await this.$api.shop.addManageProduct(form);
      this.showDialog = false;
      this.$parent.getManageProductList();
    },
    openAddShow() {
      this.$emit("openAddShow");
    },
    deleteScript() {
      this.$confirm("此操作将永久删除该剧本, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        this.$set(this.loadingArr, 3, true);
        await this.$api.shop
          .deleteGoods({
            data: { sellNowProductIds: [this.interShop.productId] },
          })
          .finally(() => {
            this.$set(this.loadingArr, 3, false);
            this.showDialog = false;
          });
        this.$parent.getManageProductList();
      });
    },
  },
  computed: {
    discount() {
      const form = this.form;
      return form.originalPrice === 0 ||
        (Number(form.nowPrice) === 0 && Number(form.originalPrice) === 0)
        ? 0
        : ((form.nowPrice / form.originalPrice) * 10).toFixed(1);
    },
  },
  watch: {
    interShop() {
    },
    discount(v) {
      this.form.discount = v;
    },
    async showDialog(newVal) {
      if (!newVal) {
        this.form = this.$options.data().form;
        this.treeData = [];
        this.clueData = [];
        this.chapterIndex = 0;
        this.clueIndex = "";
        this.checkedKeys = [];
        this.payTipChapterIndex = 0;
        this.payTipIndex = "";
      } else {
        const v = this.interShop;
        if (v) {
          if (v.productType === 1) {
            this.getPayTip(v.scenarioId);
            this.payTipChapterIndex = v.payChapterTip;
            this.payTipIndex = v.payStageTip ? v.payStageTip + "" : "";
            this.form = { ...this.form, ...v };
            await this.getScenarioPaidContent(v.scenarioId);
            const checkedKeys = v.paidContentList.filter(
              (item) => item.length > 5
            );
            const arr = [];
            checkedKeys.forEach((item) => {
              if (
                !this.$refs.tree.getNode(item).childNodes ||
                !this.$refs.tree.getNode(item).childNodes.length
              ) {
                arr.push(item);
              }
            });
            this.checkedKeys = arr;
          } else {
            this.form = { ...this.form, ...v };
            const [id] = this.form.paidContentList;
            await this.getScenarioPaidClue(v.scenarioId);
            this.clueData.some(({ childTree }, indexs) => {
              return childTree.some(({ id: itemId }, index) => {
                if (itemId == id) {
                  this.clueIndex = index;
                  this.chapterIndex = indexs;
                }
                return itemId == id;
              });
            });
          }
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "@style/form.scss";
::v-deep p {
  margin: 0;
}
.pay-tip {
  margin-left: 20px;
  color: #999999;
  font-size: 14px;
}
</style>