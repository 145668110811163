<template>
  <el-dialog top="3vh" :visible.sync="showDialog" width="760px">
    <div slot="title" style="text-align: center" class="slot-title">
      <span style="background: #eceafa; padding: 5px">
        {{ form.productName }}
      </span>
      <el-button
        v-if="!form.id || form.saleStatus === 0"
        class="slot-button"
        size="mini"
        @click="checkZeroPrice(0, true)"
        >保存并复制</el-button
      >
      <el-button
        v-else
        class="slot-button"
        size="mini"
        @click="checkZeroPrice(5, true)"
        >复制</el-button
      >
    </div>
    <div class="dialog">
      <div class="flex-row flex-ac">
        <div class="title">
          <span style="color: red; font-size: 16px">*</span>名称
        </div>
        <el-select
          size="mini"
          placeholder="请选择已上架剧本"
          @change="changeName"
          v-model="form.scenarioId"
        >
          <el-option
            v-for="(option, index) in scripts"
            :key="index"
            :label="option.scenarioName"
            :value="option.id"
          ></el-option>
        </el-select>
        <span
          class="link m-l-10"
          v-if="form.productType === 2"
          @click="toScriptPage(0)"
          >打开剧本 ></span
        >
        <span
          class="link m-l-10"
          v-if="form.productType === 1"
          @click="toScriptPage(1)"
          >作品管理 ></span
        >
      </div>
      <div class="flex-row flex-ac">
        <div class="title">
          <span style="color: red; font-size: 16px">*</span>商品类型
        </div>
        <el-button-group style="overflow: hidden; border-radius: 10px">
          <el-button
            round
            @click="form.productType = 1"
            :type="form.productType === 1 ? 'primary' : ''"
            size="mini"
            >单人本</el-button
          >
          <el-button
            round
            @click="form.productType = 2"
            :type="form.productType === 2 ? 'primary' : ''"
            size="mini"
            >完整本</el-button
          >
        </el-button-group>
      </div>
      <div class="flex-row flex-ac">
        <div class="title">
          <span style="color: red; font-size: 16px">*</span>价格
        </div>
        <span>原价：</span>
        <el-input
          v-model="form.originalPrice"
          style="width: 65px; margin-right: 20px"
          size="mini"
        ></el-input>
        <span>现价：</span>
        <el-input
          v-model="form.nowPrice"
          style="width: 65px; margin-right: 20px"
          size="mini"
        ></el-input>
        <el-radio-group v-model="form.buyAttribute" size="mini">
          <el-radio-button label="1">人民币</el-radio-button>
          <el-radio-button label="2">点券</el-radio-button>
          <el-radio-button label="3">金币</el-radio-button>
        </el-radio-group>
      </div>
      <div class="flex-row flex-ac">
        <div class="title">折扣</div>
        <span style="font-weight: 600; margin-right: 10px">{{ discount }}</span
        >折
      </div>
      <div class="flex-row flex-as">
        <div class="title">
          <span style="color: red; font-size: 16px">*</span>购买限制
        </div>
        <div>
          <div class="flex-row flex-ac">
            <!-- <div @click="buyLimit1 = !buyLimit1" class="my-radio">
              <div v-if="buyLimit1" class="my-radio-choice"></div>
            </div> -->
            <el-select size="mini" v-model="form.buyRestrict">
              <el-option
                v-for="(option, index) in selectArr.limitPeople"
                :key="index"
                :label="option.label"
                :value="option.value"
              ></el-option>
            </el-select>
            <span style="margin-left: 10px">可购买此商品；</span>
          </div>
          <div class="flex-row flex-ac">
            <!-- <div @click="buyLimit2 = !buyLimit2" class="my-radio">
              <div v-if="buyLimit2" class="my-radio-choice"></div>
            </div> -->
            <span class="m-r-10">此商品共:</span>
            <el-button
              :type="form.count === -1 ? 'primary' : ''"
              @click="form.count = form.count === -1 ? 0 : -1"
              size="mini"
              >无限</el-button
            >
            <el-input
              :disabled="form.count === -1"
              v-model="form.count"
              style="width: 70px"
              size="mini"
              maxlength="4"
              placeholder="请输入"
            >
              <span slot="suffix" class="suffix">件</span>
            </el-input>
            <span class="m-lr-10">每个账号可买:</span>
            <el-button
              :type="form.accountBuyCount === -1 ? 'primary' : ''"
              @click="
                form.accountBuyCount = form.accountBuyCount === -1 ? 0 : -1
              "
              size="mini"
              >无限</el-button
            >
            <el-input
              :disabled="form.accountBuyCount === -1"
              v-model="form.accountBuyCount"
              style="width: 70px"
              size="mini"
              maxlength="4"
              placeholder="请输入"
            >
              <span slot="suffix" class="suffix">件</span>
            </el-input>
          </div>
        </div>
      </div>
      <!-- <div class="flex-row flex-ac">
        <div class="title">
          <span style="color: red; font-size: 16px">*</span>商城展示
        </div>
        <el-select
          multiple
          size="mini"
          placeholder="选择展区"
          v-model="form.productExhibitionRelevanceBoList"
        >
          <el-option
            v-for="option in tempZone"
            :key="option.value"
            :label="option.label"
            :value="option.value"
          ></el-option>
        </el-select>
        <span @click="openAddShow" class="link m-l-10">添加展区 ></span>
      </div> -->
      <div class="flex-row flex-ac">
        <div class="title">
          <span style="color: red; font-size: 16px">*</span>促销标语
        </div>
        <el-input
          v-model="form.promotionalBanners"
          style="width: 100px"
          size="mini"
          maxlength="4"
          placeholder="请输入"
        ></el-input>
        <span class="m-l-10 remark-text">4个字以下</span>
      </div>
      <div class="flex-row flex-as p-r">
        <div class="title">
          <span style="color: red; font-size: 16px">*</span>商品说明
        </div>
        <el-input
          v-model="form.productExplain"
          style="width: 430px"
          size="mini"
          type="textarea"
          placeholder="请输入说明"
        ></el-input>
        <span class="remark-text p-rb-0">（必须提及赠品内容，建议60字内）</span>
      </div>
      <!-- <div class="flex-row flex-as">
        <div class="title">
          <span style="color: red; font-size: 16px">*</span>赠品
        </div>
        <div style="position: relative">
          <div
            class="flex-row flex-ac"
            v-for="(item, index) in form.productDiscountsGiveBoList"
            :key="index"
          >
            <div v-if="item.giftType === 0">
              <el-select
                size="mini"
                v-model="item.giveProductId"
                placeholder="请选择商品"
              >
                <el-option
                  v-for="(option, index) in goods"
                  :key="index"
                  :label="option.productName"
                  :value="option.id"
                ></el-option>
              </el-select>
              <el-input
                v-model.number="item.count"
                style="width: 70px; margin: 0 30px"
                size="mini"
                oninput="value=value.replace(/[^\d]/g,'')"
              >
                <span slot="suffix" style="display: block; margin-top: 5px"
                  >件</span
                >
              </el-input>
            </div>
            <div v-if="item.giftType === 1">
              <el-select
                size="mini"
                v-model="item.giveDiscountsId"
                placeholder="请选择优惠券"
              >
                <el-option
                  v-for="(option, index) in coupon"
                  :key="index"
                  :label="option.discountsName"
                  :value="option.id"
                ></el-option>
              </el-select>
              <el-input
                v-model.number="item.count"
                style="width: 70px; margin: 0 30px"
                size="mini"
                oninput="value=value.replace(/[^\d]/g,'')"
              >
                <span slot="suffix" style="display: block; margin-top: 5px"
                  >件</span
                >
              </el-input>
            </div>
            <div v-if="item.giftType === 2">
              <el-input
                v-model.number="item.giveGold"
                style="width: 193px"
                size="mini"
                oninput="value=value.replace(/[^\d]/g,'')"
              >
              </el-input>
              <span class="m-l-30 m-r-70">金币</span>
            </div>
            <div v-if="item.giftType === 3">
              <el-input
                v-model.number="item.giveCoupon"
                style="width: 193px"
                size="mini"
                oninput="value=value.replace(/[^\d]/g,'')"
              >
              </el-input>
              <span class="m-l-30 m-r-70">点券</span>
            </div>

            <el-button-group>
              <el-button
                @click="
                  item.giftType = 2;
                  item.name = '金币';
                "
                :type="item.giftType === 2 ? 'primary' : ''"
                round
                size="mini"
                >金币</el-button
              >
              <el-button
                @click="
                  item.giftType = 3;
                  item.name = '点券';
                "
                :type="item.giftType === 3 ? 'primary' : ''"
                round
                size="mini"
                >点券</el-button
              >
              <el-button
                @click="item.giftType = 0"
                :type="item.giftType === 0 ? 'primary' : ''"
                size="mini"
                >商品</el-button
              >
              <el-button
                @click="item.giftType = 1"
                :type="item.giftType === 1 ? 'primary' : ''"
                round
                size="mini"
                >优惠券</el-button
              >
            </el-button-group>

            <img
              @click="deleteGift(index)"
              class="pointer m-l-20"
              src="../../../../../assets/del_btn_n.png"
              alt=""
            />
          </div>
          <el-button @click="addGift" size="mini" class="content-add"
            >添加</el-button
          >
        </div>
      </div> -->
    </div>
    <span slot="footer" class="dialog-footer" v-if="form.saleStatus !== 1">
      <el-button v-if="id" size="mini" plain type="danger" @click="deleteScript"
        >删除</el-button
      >
      <el-button
        :loading="loadingArr[0]"
        size="mini"
        type="danger"
        @click="checkZeroPrice(0)"
        v-if="form.saleStatus !== 2"
        >保存未发售</el-button
      >
      <el-button
        :loading="loadingArr[2]"
        size="mini"
        type="info"
        plain
        @click="checkZeroPrice(2)"
        >停售</el-button
      >
      <el-button
        :loading="loadingArr[1]"
        size="mini"
        type="primary"
        @click="checkZeroPrice(1)"
        >立即发售</el-button
      >
    </span>
    <span
      slot="footer"
      class="dialog-footer"
      style="color: #999; font-size: 18px"
      v-else
    >
      该剧本现在为发售状态，无法编辑
    </span>
  </el-dialog>
</template>

<script>
import { deepCopy } from "@tools";
import { formRule } from "@tools";
export default {
  props: {
    id: {
      default: null,
    },
  },
  data() {
    this.selectArr = {
      limitPeople: [
        { label: "所有人", value: 1 },
        // { label: "首充用户", value: 2 },
      ],
    };
    this.formRule = [
      {
        label: "productName",
        name: "剧本名称",
        rule: {
          required: true,
        },
      },
      {
        label: "nowPrice",
        name: "现价",
        rule: {
          required: true,
          noNegative: true,
        },
      },
      {
        label: "promotionalBanners",
        name: "促销标语",
        rule: {
          required: true,
        },
      },
      {
        label: "originalPrice",
        name: "原价",
        rule: {
          required: true,
          noNegative: true,
        },
      },
      {
        label: "accountBuyCount",
        name: "每个账号可买数量",
        rule: {
          required: true,
          zero: true,
        },
      },
      {
        label: "count",
        name: "总商品数",
        rule: {
          required: true,
          zero: true,
        },
      },
      {
        label: "productExplain",
        name: "商品说明",
        rule: {
          required: true,
        },
      },
    ];
    return {
      scripts: [],
      zone: [],
      showDialog: false,
      goods: [],
      coupon: [],
      // buyLimit1: true,
      // buyLimit2: true,
      form: {
        productName: "",
        productCategory: 1,
        productType: 1,
        buyAttribute: 2,
        originalPrice: 0,
        nowPrice: 0,
        discount: 0,
        scenarioId: null,
        buyRestrict: 1,
        count: -1,
        accountBuyCount: -1,
        promotionalBanners: "",
        productExplain: "",
        productDiscountsGiveBoList: [],
        productExhibitionRelevanceBoList: [],
        saleStatus: 0,
      },

      loadingArr: [false, false, false, false],
    };
  },
  created() {
    this.getScriptList();
  },
  methods: {
    async getScriptList() {
      const data = await this.$api.shop.getScriptList({
        page: 1,
        limit: 100,
      });
      this.scripts = data.data;
    },
    async getZoneList() {
      const data = await this.$api.shop.getZoneDetailList();
      this.zone = data.map((item) => {
        item.productExhibitionRelevanceBoList ||
          (item.productExhibitionRelevanceBoList = []);
        return item;
      });
    },
    checkZeroPrice(op, isCopy) {
      if (!formRule(this.form, this.formRule)) return;
      if (this.form.nowPrice === 0 || this.form.nowPrice === "0") {
        if(this.form.buyAttribute == '1') {
          this.$message.warning('人民币价格不可设置为0')
          return
        }
        this.$confirm("您将操作一个免费剧本, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          this.addScript(op, isCopy);
        });
      } else {
        this.addScript(op, isCopy);
      }
    },
    async addScript(op, isCopy) {
      if (isCopy) {
        const obj = JSON.parse(JSON.stringify(this.form));
        this.$store.commit("setCopyScript", obj);
        this.$message.success("复制成功！");
        if (op === 5) return;
      }
      this.$set(this.loadingArr, op, true);
      this.form.discount = this.discount / 10;
      await this.$api.shop[this.id ? "editShop" : "addShop"](
        this.checkParams(op)
      ).finally(() => {
        this.$set(this.loadingArr, op, false);
      });
      this.showDialog = false;
      this.$emit("getShopList");
    },
    deleteContent(index) {
      this.form.content.splice(index, 1);
    },
    addContent() {
      this.form.content.push({ label: "asd", value: 1 });
    },
    openAddShow() {
      this.$emit("openAddShow");
    },
    changeName(id) {
      this.form.productName = this.scripts.find(
        (item) => item.id === id
      ).scenarioName;
    },
    async getScriptDetail(id) {
      const data = await this.$api.shop.getGoodsDetail(id);
      data.productExhibitionRelevanceBoList = data.productExhibitionRelevanceBoList.map(
        (item) => {
          return item.exhibitionId;
        }
      );
      !data.productDiscountsGiveBoList &&
        (data.productDiscountsGiveBoList = []);
      data.productDiscountsGiveBoList = data.productDiscountsGiveBoList.map(
        (item) => {
          item.giftType = this.checkGiftType(item);
          return item;
        }
      );
      Object.assign(this.form, data);
    },
    checkGiftType(obj) {
      var type = null;
      if (obj.giveProductId !== "0") {
        type = 0;
      } else if (obj.giveDiscountsId !== "0") {
        type = 1;
      } else if (obj.giveGold !== 0) {
        type = 2;
      } else if (obj.giveCoupon !== 0) {
        type = 3;
      }
      return type;
    },
    deleteScript() {
      this.$confirm("此操作将永久删除该剧本, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        this.$set(this.loadingArr, 3, true);
        await this.$api.shop
          .deleteGoods({ data: { sellNowProductIds: [this.id] } })
          .finally(() => {
            this.$set(this.loadingArr, 3, false);
            this.showDialog = false;
          });
        this.$emit("getShopList");
      });
    },
    toScriptPage(type) {
      if (type) {
        this.$router.push({ path: "/home/works" });
      } else {
        if (!this.form.scenarioId) {
          this.$message.warning("请选择剧本");
          return;
        }
        window.localStorage.setItem("scenarioId", this.form.scenarioId);
        this.$router.push({ path: "/entry" });
      }
    },
    deleteGift(index) {
      this.form.productDiscountsGiveBoList.splice(index, 1);
    },
    addGift() {
      this.form.productDiscountsGiveBoList.push({
        giftType: 1,
        giveProductId: "",
        giveDiscountsId: "",
        giveGold: 1,
        giveCoupon: 1,
        count: 1,
        name: "",
      });
    },
    async getGoodsAndCoupon() {
      const params = { limit: 100, page: 1 };
      const { data: goods } = await this.$api.shop.getShopList(params);
      const { data: coupon } = await this.$api.shop.getDiscountList(params);
      this.goods = goods;
      this.coupon = coupon;
    },
    checkParams(op) {
      const obj = JSON.parse(JSON.stringify(this.form));
      obj.saleStatus = op;
      obj.productExhibitionRelevanceBoList = obj.productExhibitionRelevanceBoList.map(
        (item) => {
          if (typeof item === "object") {
            delete item.id;
            return item;
          } else {
            const o = deepCopy(this.zone.find((v) => v.id === item));
            o.exhibitionId = o.id;
            delete o.id;
            o.sort = o.productExhibitionRelevanceVOList.length;
            return o;
          }
        }
      );
      obj.whetherComplimentary = obj.productDiscountsGiveBoList.length ? 1 : 0;
      obj.productDiscountsGiveBoList = obj.productDiscountsGiveBoList.map(
        (item) => {
          var gift = {};
          const arr1 = [
            "giveProductId",
            "giveDiscountsId",
            "giveGold",
            "giveCoupon",
          ];
          const arr2 = ["goods", "coupon", "productName", "discountsName"];
          const type = item.giftType;
          gift[arr1[type]] = item[arr1[type]];
          if (type === 0 || type === 1) {
            gift.name = this[arr2[type]].find((o) => o.id === item[arr1[type]])[
              arr2[type + 2]
            ];
            gift.count = item.count;
          }
          return gift;
        }
      );
      return obj;
    },
  },
  computed: {
    discount() {
      const form = this.form;
      return form.originalPrice === 0 ||
        (Number(form.nowPrice) === 0 && Number(form.originalPrice) === 0)
        ? 0
        : ((form.nowPrice / form.originalPrice) * 10).toFixed(1);
    },
    tempZone() {
      return this.zone.map((item) => {
        return {
          value: item.id,
          label: item.exhibitionName,
        };
      });
    },
  },
  watch: {
    showDialog(newVal) {
      if (!newVal) {
        this.form = this.$options.data().form;
      } else {
        this.getGoodsAndCoupon();
        this.getZoneList();
        if (this.id) {
          this.getScriptDetail(this.id);
        } else {
          if (this.$store.state.copyScript) {
            const script = JSON.parse(
              JSON.stringify(this.$store.state.copyScript)
            );
            delete script.saleStatus;
            delete script.id;
            // script.scenarioId = null
            Object.assign(this.form, script);
            this.$store.commit("setCopyScript", null);
          }
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "@style/form.scss";
</style>