<template>
  <el-dialog top="3vh" :visible.sync="showDialog" width="780px">
    <div slot="title" style="text-align: center" class="slot-title">
      <span style="background: #eceafa; padding: 5px">
        {{ form.productName }}
      </span>
      <!-- <el-button class="slot-button" size="mini">保存并复制</el-button> -->
    </div>
    <div class="dialog">
      <div class="flex-row flex-ac">
        <div class="title">
          <span style="color: red; font-size: 16px">*</span>名称
        </div>
        <el-input
          v-model="form.productName"
          style="width: 212px"
          size="mini"
          placeholder="请输入名称"
        ></el-input>
      </div>
      <div class="flex-row flex-ac">
        <div class="title">
          <span style="color: red; font-size: 16px">*</span>商品类型
        </div>
        <el-button-group style="overflow: hidden; border-radius: 10px">
          <el-button
            round
            @click="form.productType = 3"
            :type="form.productType === 3 ? 'primary' : ''"
            size="mini"
            >点券</el-button
          >
          <!-- <el-button
            @click="form.productType = 4"
            :type="form.productType === 4 ? 'primary' : ''"
            size="mini"
            >其他商品</el-button
          >
          <el-button
            round
            @click="form.productType = 5"
            :type="form.productType === 5 ? 'primary' : ''"
            size="mini"
            >商品礼包</el-button
          > -->
        </el-button-group>
      </div>
      <div v-if="form.productType === 5" class="flex-row flex-as">
        <div class="title">
          <span style="color: red; font-size: 16px">*</span>内容
        </div>
        <div style="position: relative">
          <div
            class="flex-row flex-ac"
            v-for="(item, index) in changeForm.productGiftBag"
            :key="index"
          >
            <el-select size="mini" v-model="changeForm.productGiftBag[index]">
              <el-option
                v-for="(option, index) in goods"
                :key="index"
                :label="option.productName"
                :value="option.id"
              ></el-option>
            </el-select>
            <!-- <el-input
              v-model="item.value"
              style="width: 70px; margin: 0 30px"
              size="mini"
              oninput="value=value.replace(/[^\d]/g,'')"
            >
              <span slot="suffix" class="suffix">件</span>
            </el-input> -->
            <img
              @click="deleteContent(index)"
              class="pointer m-l-20"
              src="../../../../assets/del_btn_n.png"
              alt=""
            />
          </div>
          <el-button @click="addContent" size="mini" class="content-add"
            >添加</el-button
          >
          <span class="content-remark">（优惠券需要放到下方赠品内）</span>
        </div>
      </div>
      <div v-if="form.productType === 4" class="flex-row flex-as">
        <div class="title">
          <span style="color: red; font-size: 16px">*</span>内容
        </div>
        <el-upload
          class="avatar-uploader"
          :action="$config.VUE_APP_URL + $filesUrl[0]"
          :show-file-list="false"
          :on-success="uploadSuccess"
          :headers="{
            Authorization: `bearer ${accessToken}`,
          }"
          :data="{
            fileType: 2,
          }"
          :before-upload="beforeUpload"
        >
          <img
            v-if="changeForm.specialPicture"
            :src="changeForm.specialPicture"
            class="avatar"
          />
          <div v-else>
            <div class="remark-text">点击上传</div>
          </div>
        </el-upload>
      </div>
      <div v-if="form.productType === 3" class="flex-row flex-as">
        <div class="title">
          <span style="color: red; font-size: 16px">*</span>内容
        </div>
        <el-input
          v-model.number="changeForm.couponCount"
          style="width: 100px"
          size="mini"
          maxlength="4"
          oninput="value=value.replace(/[^\d]/g,'')"
        >
          <span slot="suffix" class="suffix">点券</span>
        </el-input>
      </div>
      <div class="flex-row flex-ac">
        <div class="title">
          <span style="color: red; font-size: 16px">*</span>价格
        </div>
        <span>原价：</span>
        <el-input
          v-model.number="form.originalPrice"
          style="width: 65px; margin-right: 20px"
          size="mini"
        ></el-input>
        <span>现价：</span>
        <el-input
          v-model="form.nowPrice"
          style="width: 65px; margin-right: 20px"
          size="mini"
        ></el-input>
        <span v-if="form.productType === 3">人民币</span>
        <el-radio-group v-else v-model="form.buyAttribute" size="mini">
          <el-radio-button label="1">点券</el-radio-button>
          <el-radio-button label="2">金币</el-radio-button>
        </el-radio-group>
      </div>
      <div class="flex-row flex-ac">
        <div class="title">折扣</div>
        <span style="font-weight: 600; margin-right: 10px"> {{ discount }}</span
        >折
      </div>
      <div class="flex-row flex-as">
        <div class="title">
          <span style="color: red; font-size: 16px">*</span>购买限制
        </div>
        <div>
          <div class="flex-row flex-ac">
            <!-- <div @click="buyLimit1 = !buyLimit1" class="my-radio">
              <div v-if="buyLimit1" class="my-radio-choice"></div>
            </div> -->
            <el-select size="mini" v-model="form.buyRestrict">
              <el-option
                v-for="(option, index) in selectArr.buyRestrict"
                :key="index"
                :label="option.label"
                :value="option.value"
              ></el-option>
            </el-select>
            <span style="margin-left: 10px">可购买此商品；</span>
          </div>
          <div class="flex-row flex-ac">
            <!-- <div @click="buyLimit2 = !buyLimit2" class="my-radio">
              <div v-if="buyLimit2" class="my-radio-choice"></div>
            </div> -->
            <span class="m-r-10">此商品共:</span>
            <el-button
              size="mini"
              @click="form.count = form.count === -1 ? 0 : -1"
              :type="form.count === -1 ? 'primary' : ''"
              >无限</el-button
            >
            <el-input
              :disabled="form.count === -1"
              v-model.number="form.count"
              style="width: 70px"
              size="mini"
              maxlength="4"
              oninput="value=value.replace(/[^\d]/g,'')"
            >
              <span slot="suffix" class="suffix">件</span>
            </el-input>
            <span class="m-lr-10">每个账号可买:</span>
            <el-button
              size="mini"
              @click="
                form.accountBuyCount = form.accountBuyCount === -1 ? 0 : -1
              "
              :type="form.accountBuyCount === -1 ? 'primary' : ''"
              >无限</el-button
            >
            <el-input
              :disabled="form.accountBuyCount === -1"
              v-model.number="form.accountBuyCount"
              style="width: 70px"
              size="mini"
              maxlength="4"
              oninput="value=value.replace(/[^\d]/g,'')"
            >
              <span slot="suffix" class="suffix">件</span>
            </el-input>
          </div>
        </div>
      </div>
      <div class="flex-row flex-ac">
        <div class="title"><span style="color: red; font-size: 16px">*</span>商城展示</div>
        <el-select
          multiple
          size="mini"
          placeholder="选择展区"
          v-model="form.productExhibitionRelevanceBoList"
        >
          <el-option
            v-for="option in tempZone"
            :key="option.value"
            :label="option.label"
            :value="option.value"
          ></el-option>
        </el-select>
        <span @click="openAddShow" class="link m-l-10">添加展区 ></span>
      </div>
      <div class="flex-row flex-ac">
        <div class="title"><span style="color: red; font-size: 16px">*</span>促销标语</div>
        <el-input
          v-model="form.promotionalBanners"
          style="width: 100px"
          size="mini"
          maxlength="4"
          placeholder="请输入"
        ></el-input>
        <span class="m-l-10 remark-text">4个字以下</span>
      </div>
      <div class="flex-row flex-as p-r">
        <div class="title"><span style="color: red; font-size: 16px">*</span>商品说明</div>
        <el-input
          v-model="form.productExplain"
          style="width: 430px"
          size="mini"
          type="textarea"
          placeholder="请输入说明"
        ></el-input>
        <span class="remark-text p-rb-0">（必须提及赠品内容，建议60字内）</span>
      </div>
      <!-- <div class="flex-row flex-as">
        <div class="title">
          赠品
        </div>
        <div style="position: relative">
          <div
            class="flex-row flex-ac"
            v-for="(item, index) in form.productDiscountsGiveBoList"
            :key="index"
          >
            <div v-if="item.giftType === 0">
              <el-select
                size="mini"
                v-model="item.giveProductId"
                placeholder="请选择商品"
              >
                <el-option
                  v-for="(option, index) in goods"
                  :key="index"
                  :label="option.productName"
                  :value="option.id"
                ></el-option>
              </el-select>
              <el-input
                v-model.number="item.count"
                style="width: 70px; margin: 0 30px"
                size="mini"
                oninput="value=value.replace(/[^\d]/g,'')"
              >
                <span slot="suffix" style="display: block; margin-top: 5px"
                  >件</span
                >
              </el-input>
            </div>
            <div v-if="item.giftType === 1">
              <el-select
                size="mini"
                v-model="item.giveDiscountsId"
                placeholder="请选择优惠券"
              >
                <el-option
                  v-for="(option, index) in coupon"
                  :key="index"
                  :label="option.discountsName"
                  :value="option.id"
                ></el-option>
              </el-select>
              <el-input
                v-model.number="item.count"
                style="width: 70px; margin: 0 30px"
                size="mini"
                oninput="value=value.replace(/[^\d]/g,'')"
              >
                <span slot="suffix" style="display: block; margin-top: 5px"
                  >件</span
                >
              </el-input>
            </div>
            <div v-if="item.giftType === 2">
              <el-input
                v-model.number="item.giveGold"
                style="width: 193px"
                size="mini"
                oninput="value=value.replace(/[^\d]/g,'')"
              >
              </el-input>
              <span class="m-l-30 m-r-70">金币</span>
            </div>
            <div v-if="item.giftType === 3">
              <el-input
                v-model.number="item.giveCoupon"
                style="width: 193px"
                size="mini"
                oninput="value=value.replace(/[^\d]/g,'')"
              >
              </el-input>
              <span class="m-l-30 m-r-70">点券</span>
            </div>

            <el-button-group>
              <el-button
                @click="
                  item.giftType = 2;
                  item.name = '金币';
                "
                :type="item.giftType === 2 ? 'primary' : ''"
                round
                size="mini"
                >金币</el-button
              >
              <el-button
                @click="
                  item.giftType = 3;
                  item.name = '点券';
                "
                :type="item.giftType === 3 ? 'primary' : ''"
                round
                size="mini"
                >点券</el-button
              >
              <el-button
                @click="item.giftType = 0"
                :type="item.giftType === 0 ? 'primary' : ''"
                size="mini"
                >商品</el-button
              >
              <el-button
                @click="item.giftType = 1"
                :type="item.giftType === 1 ? 'primary' : ''"
                round
                size="mini"
                >优惠券</el-button
              >
            </el-button-group>

            <img
              @click="deleteGift(index)"
              class="pointer m-l-20"
              src="../../../../../assets/del_btn_n.png"
              alt=""
            />
          </div>
          <el-button @click="addGift" size="mini" class="content-add"
            >添加</el-button
          >
        </div>
      </div> -->
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button
        v-if="id"
        :loading="loadingArr[3]"
        size="mini"
        plain
        type="danger"
        @click="deleteGoods"
        >删除</el-button
      >
      <el-button
        :loading="loadingArr[0]"
        size="mini"
        type="danger"
        v-if="!id || (id && !form.saleStatus)"
        @click="addShop(0)"
        >保存未发售</el-button
      >
      <el-button
        :disabled="!id || (id && !form.saleStatus)"
        v-if="form.saleStatus !== 2"
        :loading="loadingArr[2]"
        size="mini"
        type="info"
        plain
        @click="saleOperation"
        >停售</el-button
      >
      <el-button
        :loading="loadingArr[1]"
        size="mini"
        type="primary"
        v-if="!id || (id && !form.saleStatus)"
        @click="addShop(1)"
        >立即发售</el-button
      >
      <el-button
        :loading="loadingArr[1]"
        size="mini"
        type="primary"
        @click="addShop(1, true)"
        v-if="isNew"
        >新建重售</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import { formRule } from '@tools'
export default {
  props: {
    id: {
      default: null,
    },
    isNew: {
      default: false,
    },
  },
  data() {
    this.selectArr = {
      buyRestrict: [
        { label: "所有人", value: 1 },
        { label: "首充用户", value: 2 },
      ],
    };
    this.formRule = [
      {
        label: "productName",
        name: "商品名称",
        rule: {
          required: true
        },
      },
      {
        label: "couponCount",
        name: "内容",
        rule: {
          required: true,
          zero: true,
        },
      },
      {
        label: "nowPrice",
        name: "现价",
        rule: {
          required: true,
          zero: true,
        },
      },
      {
        label: "originalPrice",
        name: "原价",
        rule: {
          required: true,
          zero: true,
        },
      },
      {
        label: "accountBuyCount",
        name: "每个账号可买数量",
        rule: {
          required: true,
          zero: true,
        },
      },
      {
        label: "count",
        name: "总商品数",
        rule: {
          required: true,
          zero: true
        },
      },
      {
        label: "productExhibitionRelevanceBoList",
        name: "展区",
        rule: {
          required: true,
          emptyArr: true
        },
      },
      {
        label: "promotionalBanners",
        name: "促销标语",
        rule: {
          required: true
        },
      },
      {
        label: "productExplain",
        name: "商品说明",
        rule: {
          required: true
        },
      },
    ];
    return {
      showDialog: false,
      zone: [],
      // buyLimit1: true,
      // buyLimit2: true,
      accessToken: localStorage.getItem("access_token"),
      changeForm: {
        specialPicture: "",
        productGiftBag: [],
        couponCount: 0,
      },
      form: {
        productName: "商品名称",
        productCategory: 2,
        productType: 3,
        buyAttribute: 1,
        originalPrice: 0,
        nowPrice: 0,
        discount: 1,
        scenarioId: null,
        buyRestrict: 1,
        count: -1,
        accountBuyCount: -1,
        promotionalBanners: "",
        productExplain: "",
        whetherComplimentary: 0,
        saleStatus: 0,
        productDiscountsGiveBoList: [],
        productExhibitionRelevanceBoList: [],
      },
      loadingArr: [false, false, false, false],
      goods: [],
      coupon: [],
    };
  },
  created() {},
  methods: {
    checkProductType (obj) {
      const changeObj = this.changeForm
      if (obj.productType === 3) {
        obj.couponCount = changeObj.couponCount;
      } else if (obj.productType === 4) {
        obj.specialPicture = changeObj.specialPicture;
      } else if (obj.productType === 5) {
        obj.productGiftBag = changeObj.productGiftBag.filter(
          (item) => item !== ""
        );
      }
    },
    async addShop(op, isNew) {
      const obj = JSON.parse(JSON.stringify(this.form))
      this.checkProductType(obj)
      if (!formRule(obj, this.formRule)) return
      this.$set(this.loadingArr, op, true);
      this.form.discount = this.discount / 10;
      await this.$api.shop[this.id && !isNew ? "editShop" : "addShop"](
        this.checkParams(op, isNew)
      ).finally(() => {
        this.$set(this.loadingArr, op, false);
      });
      this.showDialog = false;
      this.$emit("getShopList");
    },
    async saleOperation() {
      this.$set(this.loadingArr, 2, true);
      await this.$api.shop.goodsStatusOperation({
        sellNowProductIds: [this.form.id],
      });
      this.$set(this.loadingArr, 2, false);
      this.showDialog = false;
      this.$emit("getShopList");
    },
    async getZoneList() {
      const data = await this.$api.shop.getZoneDetailList();
      this.zone = data.map((item) => {
        item.productExhibitionRelevanceBoList ||
          (item.productExhibitionRelevanceBoList = []);
        return item;
      });
    },
    async getGoodsAndCoupon() {
      const params = { limit: -1, page: 1 };
      const { data: goods } = await this.$api.shop.getShopList(params);
      const { data: coupon } = await this.$api.shop.getDiscountList(params);
      this.goods = goods;
      this.coupon = coupon;
    },
    deleteGift(index) {
      this.form.productDiscountsGiveBoList.splice(index, 1);
    },
    addGift() {
      this.form.productDiscountsGiveBoList.push({
        giftType: 1,
        giveProductId: "",
        giveDiscountsId: "",
        giveGold: 1,
        giveCoupon: 1,
        count: 1,
        name: "",
      });
    },
    addContent() {
      this.changeForm.productGiftBag.push("");
    },
    deleteContent(index) {
      this.changeForm.productGiftBag.splice(index, 1);
    },
    openAddShow() {
      this.$emit("openAddShow");
    },
    beforeUpload(file) {
      const isPNG = file.type === "image/png";
      const isJPG = file.type === "image/jpeg";
      const isGIF = file.type === "image/gif";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!(isPNG || isJPG || isGIF)) {
        this.$message.error("上传图片只能是 PNG或JPG, GIF格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 2MB!");
      }
      return (isPNG || isJPG || isGIF) && isLt2M;
    },
    uploadSuccess(res) {
      this.changeForm.specialPicture = res.url;
    },
    checkParams(op, isNew) {
      const obj = JSON.parse(JSON.stringify(this.form));
      isNew && delete obj.id;
      obj.saleStatus = op;
      this.checkProductType(obj)
      // !this.buyLimit1 && (obj.buyRestrict = 1)
      // if (!this.buyLimit2) {
      //   obj.count = -1
      //   obj.accountBuyCount = -1
      // }
      obj.productExhibitionRelevanceBoList = obj.productExhibitionRelevanceBoList.map(
        (item) => {
          if (typeof item === "object") {
            delete item.id;
            return item;
          } else {
            const o = this.zone.find((v) => v.id === item);
            o.exhibitionId = o.id;
            delete o.id;
            o.sort = o.productExhibitionRelevanceVOList.length;
            return o;
          }
        }
      );
      obj.whetherComplimentary = obj.productDiscountsGiveBoList.length ? 1 : 0;
      obj.productDiscountsGiveBoList = obj.productDiscountsGiveBoList.map(
        (item) => {
          var gift = {};
          const arr1 = [
            "giveProductId",
            "giveDiscountsId",
            "giveGold",
            "giveCoupon",
          ];
          const arr2 = ["goods", "coupon", "productName", "discountsName"];
          const type = item.giftType;
          gift[arr1[type]] = item[arr1[type]];
          if (type === 0 || type === 1) {
            gift.name = this[arr2[type]].find((o) => o.id === item[arr1[type]])[
              arr2[type + 2]
            ];
            gift.count = item.count;
          }
          return gift;
        }
      );
      return obj;
    },
    checkGiftType(obj) {
      var type = null;
      if (obj.giveProductId !== "0") {
        type = 0;
      } else if (obj.giveDiscountsId !== "0") {
        type = 1;
      } else if (obj.giveGold !== 0) {
        type = 2;
      } else if (obj.giveCoupon !== 0) {
        type = 3;
      }
      return type;
    },
    async getGoodsDetail(id) {
      const data = await this.$api.shop.getGoodsDetail(id);
      // data.buyRestrict === 1 && (this.buyLimit1 = true)
      !data.productDiscountsGiveBoList &&
        (data.productDiscountsGiveBoList = []);
      data.couponCount && (this.changeForm.couponCount = data.couponCount);
      data.productType === 5 &&
        (this.changeForm.productGiftBag = data.productGiftBag);
      data.productExhibitionRelevanceBoList = data.productExhibitionRelevanceBoList.map(
        (item) => {
          return item.exhibitionId;
        }
      );
      data.productDiscountsGiveBoList = data.productDiscountsGiveBoList.map(
        (item) => {
          item.giftType = this.checkGiftType(item);
          return item;
        }
      );
      Object.assign(this.form, data);
    },
    deleteGoods() {
      this.$confirm("此操作将永久删除该商品, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        this.$set(this.loadingArr, 3, true);
        await this.$api.shop
          .deleteGoods({ data: { sellNowProductIds: [this.id] } })
          .finally(() => {
            this.$set(this.loadingArr, 3, false);
            this.showDialog = false;
          });
        this.$emit("getShopList");
      });
    },
  },
  watch: {
    showDialog(newVal) {
      if (!newVal) {
        this.form = this.$options.data().form;
        this.changeForm = this.$options.data().changeForm;
      } else {
        this.getZoneList();
        this.getGoodsAndCoupon();
        this.id && this.getGoodsDetail(this.id);
      }
    },
  },
  computed: {
    discount() {
      const form = this.form;
      return form.originalPrice === 0
        ? 0
        : ((form.nowPrice / form.originalPrice) * 10).toFixed(1);
    },
    tempZone() {
      return this.zone.map((item) => {
        return {
          value: item.id,
          label: item.exhibitionName,
        };
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import "@style/form.scss";
</style>