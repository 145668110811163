<template>
  <el-dialog top="3vh" :visible.sync="showDialog" width="590px">
    <div slot="title" style="text-align: center" class="slot-title">
      <span style="background: #eceafa; padding: 5px"> 商城展区 </span>
    </div>
    <div class="dialog">
      <div class="m-b-10" v-for="(item, index) in arr" :key="index">
        <div class="flex-row space-between" style="margin-right: 20px">
          <div class="flex-row flex-ac">
            <div class="title" v-if="!item.isEdit">
              <span style="color: red; font-size: 16px">*</span
              >{{ item.exhibitionName }}
            </div>
            <el-input
              v-else
              v-model="item.exhibitionName"
              class="m-r"
              style="width: 90px"
              size="mini"
              placeholder="请输入"
              maxlength="4"
            ></el-input>
            <img
              @click="item.isEdit = !item.isEdit"
              class="pointer"
              src="../../../../assets/edit-name.png"
              alt=""
            />
          </div>
          <div>
            <el-button
              :disabled="index === 0"
              size="mini"
              @click="topShow(index)"
              >置顶</el-button
            >
            <el-button-group class="m-lr-20">
              <el-button
                :disabled="index === 0"
                size="mini"
                icon="el-icon-arrow-up"
                @click="exchangeArr(index, index - 1)"
                >上移</el-button
              >
              <el-button
                size="mini"
                :disabled="index === arr.length - 1"
                @click="exchangeArr(index, index + 1)"
                >下移<i class="el-icon-arrow-down el-icon--right"></i
              ></el-button>
            </el-button-group>
            <el-button
              plain
              type="danger"
              size="mini"
              @click="deleteShow(index)"
              >删除分区</el-button
            >
          </div>
        </div>
        <div style="position: relative; margin-left: 75px">
          <div
            class="flex-row flex-ac"
            v-for="(item2, index2) in item.productExhibitionRelevanceVOList"
            :key="index2"
          >
            <el-select
              style="width: 150px"
              size="mini"
              v-model="item2.productId"
            >
              <el-option
                v-for="(item3, index3) in goodsList"
                :key="index3"
                :label="item3.productName"
                :value="item3.id"
              />
            </el-select>
            <div
              class="m-lr-20 pointer"
              :class="{ disable: index2 === 0 }"
              @click="topShow(index2, item.productExhibitionRelevanceVOList)"
            >
              置顶
            </div>
            <div
              class="m-l-40 pointer"
              :class="{ disable: index2 === 0 }"
              @click="
                exchangeArr(
                  index2,
                  index2 - 1,
                  item.productExhibitionRelevanceVOList
                )
              "
            >
              上移
            </div>
            <div
              class="m-l-30 m-r-50 pointer"
              :class="{
                disable:
                  index2 === item.productExhibitionRelevanceVOList.length - 1,
              }"
              @click="
                exchangeArr(
                  index2,
                  index2 + 1,
                  item.productExhibitionRelevanceVOList
                )
              "
            >
              下移
            </div>
            <img
              @click="deleteShow(index2, item.productExhibitionRelevanceVOList)"
              class="pointer"
              src="../../../../assets/del_btn_n.png"
              alt=""
            />
          </div>
          <el-button
            @click="addShow(item.productExhibitionRelevanceVOList)"
            size="mini"
            style="margin-left: 40px"
            >添加</el-button
          >
        </div>
      </div>
    </div>
    <el-button @click="addShow(null)" size="mini" class="content-add"
      >添加分区</el-button
    >
    <span slot="footer" class="dialog-footer">
      <el-button size="mini" @click="showDialog = false">取消</el-button>
      <el-button
        :loading="loading"
        size="mini"
        type="primary"
        @click="addAllShow"
        >保存</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import { removeDup } from '@tools'
export default {
  data() {
    return {
      showDialog: false,
      loading: false,
      arr: [
        {
          exhibitionName: "展区名称",
          id: "",
          isEdit: false,
          productExhibitionRelevanceVOList: [
            {
              exhibitionName: "",
              id: "",
              exhibitionId: "",
              productId: "",
              sort: "",
            },
          ],
        },
      ],
      tempArr: [
        {
          exhibitionName: "展区名称",
          id: "",
          isEdit: false,
          productExhibitionRelevanceVOList: [
            {
              exhibitionName: "",
              id: "",
              exhibitionId: "",
              productId: "",
              sort: "",
            },
          ],
        },
      ],
      goodsList: [],
    };
  },
  created() {},
  methods: {
    async getShopList() {
      const data = await this.$api.shop.getShopList({
        page: 1,
        limit: 1000,
        productCategory: 2,
        saleStatus: [1]
      });
      this.goodsList = data.data;
    },
    async getZoneList() {
      const data = await this.$api.shop.getZoneDetailList();
      this.arr = data.map((item) => {
        item.isEdit = false;
        item.productExhibitionRelevanceVOList ||
          (item.productExhibitionRelevanceVOList = []);
        // item.productExhibitionRelevanceVOList = item.productExhibitionRelevanceVOList.filter(
        //   (obj) => this.goodsList.find((o) => o.id === obj.productId)
        // );
        return item;
      });
    },
    addShow(item) {
      const arr = item || this.arr;
      const data = this.$options.data().arr[0];
      arr.push(item ? data.productExhibitionRelevanceVOList[0] : data);
    },
    topShow(index, item) {
      const arr = item || this.arr;
      arr.unshift(arr.splice(index, 1)[0]);
    },
    async addAllShow() {
      this.loading = true;
      await this.$api.shop
        .addShow({ productExhibitionVoList: this.dealArr() })
        .finally(() => {
          this.loading = false;
        });
      this.showDialog = false;
      this.$emit("getZoneList");
    },
    dealArr() {
      return this.arr.map((item1) => {
        delete item1.isEdit;
        item1.productExhibitionRelevanceVOList = removeDup(item1.productExhibitionRelevanceVOList.filter(
          (item2, index2) => {
            item1.id && (item2.exhibitionId = item1.id);
            item2.exhibitionName = item1.exhibitionName;
            item2.sort = index2;
            return item2.productId;
          }
        ), 'productId')
        return item1;
      });
    },
    // 交换数组元素位置
    exchangeArr(index1, index2, item) {
      const arr = item || this.arr;
      if (!arr[index2]) return;
      const item1 = arr.slice(index1, index1 + 1)[0];
      const item2 = arr.slice(index2, index2 + 1)[0];
      arr.splice(index1, 1, item2);
      arr.splice(index2, 1, item1);
    },
    deleteShow(index, item) {
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        const arr = item || this.arr;
        arr.splice(index, 1);
        this.$message({
          type: "success",
          message: "删除成功!",
        });
      });
    },
    // checkGoods(list, item) {
    //   const findIndex = list.find(o => o.productId === item.productId)
    //   if (findIndex) {
    //     this.$message.warning('此分区已关联该商品')
    //     item.productId = null
    //   }
    // },
  },
  watch: {
    showDialog(newVal) {
      if (!newVal) {
        this.arr = this.$options.data().arr;
      } else {
        this.getZoneList();
        this.getShopList();
      }
    },
  },
};
</script>

<style scoped lang="scss">
.title {
  width: auto;
}
</style>                                                           